<template>
  <v-container
    style="padding: 0px"
    class="mw-1150 d-flex justify-space-between"
  >
    <v-container>
      <div class="mt-2">
        <BreadCrumbs :items="items"></BreadCrumbs>
      </div>
      <Loading :loading="isLoading"></Loading>
      <div
        :class="`${
          $vuetify.breakpoint.smAndDown ? '' : 'd-flex justify-space-between'
        } align-center  mb-7 mt-1`"
      >
        <Topic
          eng="Training Course History"
          thai="ประวัติการเข้าร่วมหลักสูตร"
        ></Topic>
      </div>
      <v-row class="mt-5">
        <v-col class="mt-4" v-for="(item, i) in histories" :key="i" cols="12">
          <!-- <CourseBigPanel :isHistory="true" :model="item"></CourseBigPanel> -->
          <div>
<!--            <Loading :loading="isLoading"></Loading>-->
            <v-btn
              v-if="item.training_course.status_highlight === 1"
              absolute
              color="#6DCDEC"
              style="z-index: 2"
              width="94px"
              height="32px"
              dark
            >
              <span class="font-weight-bold text-capitalize font-14">
                Highlight!
              </span>
            </v-btn>
            <v-card elevation="4" class="px-3">
              <v-row>
                <v-col
                  md="4"
                  sm="4"
                  cols="12"
                  class="pointer"
                  @click="
                    $router.push(
                      `/course/${
                        item.training_course && item.training_course.id
                      }`
                    )
                  "
                >
                  <v-img
                    v-if="
                      item.training_course &&
                      item.training_course.image_training_courses
                    "
                    height="100%"
                    :aspect-ratio="16 / 9"
                    :src="
                      storage_path + item.training_course.image_training_courses
                    "
                  ></v-img>
                  <v-img
                    v-else
                    height="100%"
                    :aspect-ratio="16 / 9"
                    src="../../assets/image/no-img-course.png"
                  ></v-img>
                </v-col>
                <v-col md="8" sm="8" cols="12" class="d-flex">
                  <v-layout justify-space-between class="d-flex flex-column">
                    <v-flex
                      class="mt-n4 mt-sm-0 pointer"
                      @click="
                        $router.push(
                          `/course/${
                            item.training_course && item.training_course.id
                          }`
                        )
                      "
                    >
                      <div class="font-24 font-w-500 ell-2">
                        {{
                          item.training_course ? item.training_course.name : ""
                        }}
                      </div>

                      <div
                        v-html="
                          item.training_course
                            ? item.training_course.course_details
                            : ''
                        "
                        class="ell-2 font-14 my-2"
                      ></div>
                    </v-flex>
                    <v-flex class="d-flex align-end pr-3">
                      <v-layout
                        class="text-center"
                        wrap
                        v-if="item.training_course"
                      >
                        <v-flex shrink class="d-flex align-center font-13">
                          <v-icon class="mr-1" small color="#4090FF">
                            mdi-calendar-text
                          </v-icon>
                          {{
                            changeDate(
                              item.training_course.open_date_courses,
                              item.training_course.open_time_courses,
                              item.training_course.end_time_courses
                            )
                          }}
                        </v-flex>
                        <v-spacer v-if="$vuetify.breakpoint.xs" />
                        <v-flex shrink class="mr-4 d-flex align-center font-13">
                          <v-icon class="ml-4 mr-2" small color="#80C843">
                            mdi-account-plus
                          </v-icon>
                          {{ item.training_course.user_course_normal_count }} /
                          {{ item.training_course.number_user }}
                        </v-flex>

                        <v-spacer />

                        <v-flex shrink class="d-flex align-center font-13">
                          <v-btn
                            v-if="
                              item.status_approve === 1 &&
                              item.training_course &&
                              item.training_course.status_certificate === 1
                            "
                            @click="getCertificate(item.training_course.id)"
                            block
                            class="mx-3 btn-blue-gradient"
                            rounded
                          >
                            <div class="mx-4 my-3">
                              <div class="font-12">ดาวน์โหลด Certificate</div>
                            </div>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12">
          <v-pagination
            class="my-10"
            v-model="paginate.page"
            :length="paginate.last_page"
            circle
            @input="changePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Topic from "../../components/Topic.vue";
import Loading from "../../components/Loading.vue";
import BreadCrumbs from "../../components/BreadCrumbs.vue";
import CourseBigPanel from "../../components/Course/CourseBigPanel.vue";
import { changeDate } from "../../plugins/date-time-convert";
import { mapState } from "vuex";
import { domain, storage_path } from "../../util/Constant";
export default {
  components: {
    BreadCrumbs,
    Loading,
    Topic,
    // CourseBigPanel,
  },
  data() {
    return {
      isHistory: true,
      storage_path: storage_path,
      changeDate: changeDate,
      histories: [],
      paginate: {
        page: 1,
        current_page: 1,
        last_page: 1,
        total: 1,
      },
      row: 4,
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "หลักสูตรอบรม",
          disabled: false,
          href: "/course",
        },
        {
          text: "ประวัติการเข้าร่วมหลักสูตร",
          disabled: true,
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      courses: (state) => state.Course.courses,
      // paginate: (state) => state.Course.paginate,
      user: (state) => state.auth.user,
      access_token: (state) => state.auth.access_token,
    }),
  },
  async created() {
    this.changePage(1);
    // const res = await this.$store.dispatch("Course/courseHistory", {
    //   row: this.row,
    //   page: this.paginate.current_page,
    // });
    //
    // this.histories = res.data;
    // this.paginate = {
    //   page: this.paginate.current_page,
    //   current_page: res.current_page,
    //   last_page: res.last_page,
    //   total: res.total,
    // };
    // this.isLoading = false;
  },
  methods: {
    changePage(page) {
      this.isLoading = true;
      // console.log(this.row,page)
      this.$store.dispatch("Course/courseHistory", {
        row: this.row,
        page: page,
      }).then(res=>{
        // console.log(res)
        this.histories = res.data;
        this.paginate = {
          page: page,
          current_page: res.current_page,
          last_page: res.last_page,
          total: res.total,
        };
        this.isLoading = false;
      });
    },
    getCertificate(id) {
      if (this.user && id) {
        const url = `${domain}/certificate-template?user_id=${this.user.id}&course_id=${id}`;
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style></style>
