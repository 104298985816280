var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mw-1150 d-flex justify-space-between",staticStyle:{"padding":"0px"}},[_c('v-container',[_c('div',{staticClass:"mt-2"},[_c('BreadCrumbs',{attrs:{"items":_vm.items}})],1),_c('Loading',{attrs:{"loading":_vm.isLoading}}),_c('div',{class:`${
          _vm.$vuetify.breakpoint.smAndDown ? '' : 'd-flex justify-space-between'
        } align-center  mb-7 mt-1`},[_c('Topic',{attrs:{"eng":"Training Course History","thai":"ประวัติการเข้าร่วมหลักสูตร"}})],1),_c('v-row',{staticClass:"mt-5"},[_vm._l((_vm.histories),function(item,i){return _c('v-col',{key:i,staticClass:"mt-4",attrs:{"cols":"12"}},[_c('div',[(item.training_course.status_highlight === 1)?_c('v-btn',{staticStyle:{"z-index":"2"},attrs:{"absolute":"","color":"#6DCDEC","width":"94px","height":"32px","dark":""}},[_c('span',{staticClass:"font-weight-bold text-capitalize font-14"},[_vm._v(" Highlight! ")])]):_vm._e(),_c('v-card',{staticClass:"px-3",attrs:{"elevation":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pointer",attrs:{"md":"4","sm":"4","cols":"12"},on:{"click":function($event){return _vm.$router.push(
                      `/course/${
                        item.training_course && item.training_course.id
                      }`
                    )}}},[(
                      item.training_course &&
                      item.training_course.image_training_courses
                    )?_c('v-img',{attrs:{"height":"100%","aspect-ratio":16 / 9,"src":_vm.storage_path + item.training_course.image_training_courses}}):_c('v-img',{attrs:{"height":"100%","aspect-ratio":16 / 9,"src":require("../../assets/image/no-img-course.png")}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"md":"8","sm":"8","cols":"12"}},[_c('v-layout',{staticClass:"d-flex flex-column",attrs:{"justify-space-between":""}},[_c('v-flex',{staticClass:"mt-n4 mt-sm-0 pointer",on:{"click":function($event){return _vm.$router.push(
                          `/course/${
                            item.training_course && item.training_course.id
                          }`
                        )}}},[_c('div',{staticClass:"font-24 font-w-500 ell-2"},[_vm._v(" "+_vm._s(item.training_course ? item.training_course.name : "")+" ")]),_c('div',{staticClass:"ell-2 font-14 my-2",domProps:{"innerHTML":_vm._s(
                          item.training_course
                            ? item.training_course.course_details
                            : ''
                        )}})]),_c('v-flex',{staticClass:"d-flex align-end pr-3"},[(item.training_course)?_c('v-layout',{staticClass:"text-center",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"d-flex align-center font-13",attrs:{"shrink":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"#4090FF"}},[_vm._v(" mdi-calendar-text ")]),_vm._v(" "+_vm._s(_vm.changeDate( item.training_course.open_date_courses, item.training_course.open_time_courses, item.training_course.end_time_courses ))+" ")],1),(_vm.$vuetify.breakpoint.xs)?_c('v-spacer'):_vm._e(),_c('v-flex',{staticClass:"mr-4 d-flex align-center font-13",attrs:{"shrink":""}},[_c('v-icon',{staticClass:"ml-4 mr-2",attrs:{"small":"","color":"#80C843"}},[_vm._v(" mdi-account-plus ")]),_vm._v(" "+_vm._s(item.training_course.user_course_normal_count)+" / "+_vm._s(item.training_course.number_user)+" ")],1),_c('v-spacer'),_c('v-flex',{staticClass:"d-flex align-center font-13",attrs:{"shrink":""}},[(
                              item.status_approve === 1 &&
                              item.training_course &&
                              item.training_course.status_certificate === 1
                            )?_c('v-btn',{staticClass:"mx-3 btn-blue-gradient",attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.getCertificate(item.training_course.id)}}},[_c('div',{staticClass:"mx-4 my-3"},[_c('div',{staticClass:"font-12"},[_vm._v("ดาวน์โหลด Certificate")])])]):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)])}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{staticClass:"my-10",attrs:{"length":_vm.paginate.last_page,"circle":""},on:{"input":_vm.changePage},model:{value:(_vm.paginate.page),callback:function ($$v) {_vm.$set(_vm.paginate, "page", $$v)},expression:"paginate.page"}})],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }