<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <v-btn
      v-if="model.status_highlight === 1"
      absolute
      color="#6DCDEC"
      style="z-index: 2"
      width="94px"
      height="32px"
      dark
    >
      <span class="font-weight-bold text-capitalize font-14"> Highlight! </span>
    </v-btn>
    <!-- <Badge
      v-else
      :releaseDate="model.open_date_recruit"
      :isFix="false"
      :height="32"
      :width="94"
    ></Badge> -->
    <v-card @click="goto(model.id)" elevation="4" class="px-3 pointer">
      <v-row>
        <v-col md="4" sm="4" cols="12">
          <v-img
            v-if="model.image_training_courses"
            height="100%"
            :aspect-ratio="16 / 9"
            :src="storage_path + model.image_training_courses"
          ></v-img>
          <!-- :class="hover ? 'img-hover' : ''" -->
          <v-img
            v-else
            height="100%"
            :aspect-ratio="16 / 9"
            src="../../assets/image/no-img-course.png"
          ></v-img>
        </v-col>
        <v-col md="8" sm="8" cols="12" class="d-flex">
          <v-layout justify-space-between class="d-flex flex-column">
            <v-flex class="mt-n4 mt-sm-0">
              <div class="font-24 font-w-500 ell-2">{{ model.name }}</div>

              <div
                v-html="model.course_details"
                class="ell-2 font-14 my-2"
              ></div>
            </v-flex>
            <v-flex class="d-flex align-end pr-3">
              <v-layout class="text-center" wrap>
                <v-flex shrink class="d-flex align-center font-13">
                  <v-icon class="mr-1" small color="#4090FF">
                    mdi-calendar-text
                  </v-icon>
                  {{
                    changeDate(
                      model.open_date_courses,
                      model.open_time_courses,
                      model.end_time_courses
                    )
                  }}
                </v-flex>
                <v-spacer v-if="$vuetify.breakpoint.xs" />
                <v-flex shrink class="mr-4 d-flex align-center font-13">
                  <v-icon class="ml-4 mr-2" small color="#80C843">
                    mdi-account-plus
                  </v-icon>
                  {{ model.user_course_normal_count }} /
                  {{ model.number_user }}
                </v-flex>

                <v-spacer />

                <v-flex shrink class="d-flex align-center font-13">
                  <v-btn
                    v-if="isHistory && access_token"
                    @click="getCertificate(model.id)"
                    block
                    class="mx-3 btn-blue-gradient"
                    rounded
                  >
                    <div class="mx-4 my-3">
                      <div class="font-12">ดาวน์โหลด Certificate</div>
                    </div>
                  </v-btn>
                  <!-- <v-btn
                    :disabled="model.user_course.length > 0"
                    v-if="!isHistory"
                    block
                    :class="`mx-3 ${
                      model.user_course.length > 0
                        ? 'btn-gray'
                        : 'btn-green-gradient'
                    }`"
                    rounded
                  >
                    <div class="mx-4 my-3">
                      <div class="font-12">{{ participantStatus(model) }}</div>
                    </div>
                  </v-btn>
                  <v-btn
                    v-else-if="model.image_certificate"
                    @click="getCertificate(model.id)"
                    block
                    class="mx-3 btn-blue-gradient"
                    rounded
                  >
                    <div class="mx-4 my-3">
                      <div class="font-12">ดาวน์โหลด Certificate</div>
                    </div>
                  </v-btn> -->
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import Badge from "../Badge.vue";
import Loading from "../../components/Loading.vue";
import noImage from "../../assets/image/no-img-course.png";
import { changeDate } from "../../plugins/date-time-convert";
import { mapState } from "vuex";
import { domain, storage_path } from "../../util/Constant";
export default {
  props: ["model", "isHistory"],
  data() {
    return {
      changeDate: changeDate,
      isLoading: false,
      noImage: noImage,
      storage_path: storage_path,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      access_token: (state) => state.auth.access_token,
    }),
  },
  methods: {
    participantStatus(model) {
      const timeNow = moment();
      const endTime = moment(model.end_date_recruit, "YYYY-MM-DD");
      if (model.user_course.length > 0) {
        if (model.user_course[0].participant_type) {
          return "เข้าร่วมหลักสูตรแล้ว";
        } else {
          return "ประสงค์เข้าร่วมหลักสูตรรอบถัดไปแล้ว";
        }
      } else if (
        model.user_course_normal_count >= model.number_user ||
        timeNow.isAfter(endTime)
      ) {
        return "ประสงค์เข้าร่วมหลักสูตรรอบถัดไป";
      } else {
        return "เข้าร่วมหลักสูตร";
      }
    },
    goto(id) {
      if (!this.isHistory) {
        this.$router.push(`/course/${id}`);
      }
    },
    getCertificate(id) {
      if (this.user && id) {
        const url = `${domain}/certificate-template?user_id=${this.user.id}&course_id=${id}`;
        window.open(url, "_blank");
      }
    },
  },
  components: {
    // Badge,
    Loading,
  },
};
</script>
<style scoped>
div >>> p {
  font-size: 12px !important;
  font-family: Prompt !important;
}
div >>> span {
  font-size: 12px !important;
  font-family: Prompt !important;
}
</style>
